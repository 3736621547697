import {
  createBrowserRouter,
  createMemoryRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
} from 'react-router-dom';

import { isDesktopApp } from 'utils/client-utils';
import { setRouter } from 'utils/get-router';
import { exposeToGlobalConsole } from 'utils/react/expose-to-global-console';

import { lazyRoutes as ro } from './route-definitions';

export const orgSettingsRoutes = (
  <>
    <Route index {...ro.SettingsOverviewPage} />
    <Route path="audio-video" {...ro.AudioVideoSettings} />
    <Route path="screen-sharing" {...ro.ScreenSharingSettings} />
    <Route path="appearance" {...ro.AppearanceSettings} />
    <Route path="sound-effects" {...ro.SoundEffectsSettings} />
    <Route path="profile" {...ro.ProfileSettings} />
    <Route path="keyboard-shortcuts">
      <Route index {...ro.KeyboardShortcutsSettings} />
      <Route path=":shortcut" {...ro.EditKeyboardShortcut} />
    </Route>
    <Route path="updates" {...ro.UpdatesSettings} />
    <Route path="misc" {...ro.MiscellaneousSettings} />
    <Route path="sign-out" {...ro.SignOutSettings} />
  </>
);

const appRoutes = (
  <>
    <Route path="/" {...ro.AppShell}>
      <Route {...ro.DashboardShell}>
        <Route path="wait-for-browser-signin" {...ro.WaitForBrowserSignin} />
        <Route path="app-onboarding" {...ro.AppOnboarding} />
        <Route path="vo" key="vo">
          <Route {...ro.RequireAuth}>
            <Route {...ro.RequireAppOnboarding}>
              <Route index {...ro.NavigateToFirstOrgId} />
              <Route path=":orgId">
                <Route index {...ro.OrgPage} />
                <Route path="settings">{orgSettingsRoutes}</Route>
                <Route path="invite" {...ro.DashboardOrgInvitePage} />
                <Route path="teams/*">
                  <Route index {...ro.OrgPage} />
                  <Route path=":teamId">
                    <Route path="edit" {...ro.DashboardAddOrEditTeamPage} />
                    <Route path="delete" {...ro.DashboardDeleteTeamConfirmationPage} />
                  </Route>
                  <Route path="new" {...ro.DashboardAddOrEditTeamPage} />
                  <Route path="all" {...ro.AllTeams} />
                </Route>
                <Route path="rooms">
                  <Route index {...ro.OrgPage} />
                  <Route path="new" {...ro.SpaceSettings} />
                  <Route path="all" {...ro.AllSpaces} />
                  <Route path=":spaceId">
                    <Route path="settings" {...ro.SpaceSettings} />
                  </Route>
                </Route>
                <Route path="instant-meetings">
                  <Route index element={<Navigate to="new" />} />
                  <Route path="new" {...ro.NewMeeting} />
                  <Route path="join" {...ro.JoinMeeting} />
                  <Route path=":instantMeetingId">
                    <Route index {...ro.OrgPage} />
                  </Route>
                </Route>
                <Route path="events">
                  <Route index element={<Navigate to="../calendar" />} />
                </Route>
                <Route path="calendar">
                  <Route path="edit" {...ro.EditAvailability} />
                  <Route path="settings" {...ro.CalendarSettings} />
                  <Route index {...ro.YourCalendar} />
                </Route>
                <Route path="session-history/:sessionHistoryId">
                  <Route index {...ro.SessionHistoryOverview} />
                  <Route path="transcript" {...ro.SessionHistoryTranscript} />
                </Route>
                <Route path="people/*">
                  <Route path=":userId" {...ro.Person} />
                  <Route path="invite" {...ro.InvitePerson} />
                  <Route path="settings" {...ro.PeopleSettings} />
                  <Route index {...ro.AllPeople} />
                </Route>
              </Route>
              <Route path=":orgId/schedule-meeting">
                <Route index {...ro.ScheduleMeeting} />
              </Route>

              <Route path="new-org">
                <Route index {...ro.DashboardNewOrgPage} />
                <Route path="manual">
                  <Route index {...ro.DashboardNewManualOrgPage} />
                  <Route path="success/:orgId" {...ro.DashboardManualOrgCreatedPage} />
                </Route>
                <Route path="slack" {...ro.DashboardNewSlackOrgPage} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="u" key="urls">
        <Route {...ro.RequireAuth}>
          <Route path="org-invite/:urlId" {...ro.AcceptOrgInvite}></Route>
        </Route>
      </Route>
    </Route>
  </>
);

const createDesktopRouter = () => {
  const desktopRouter = createMemoryRouter(createRoutesFromElements(<>{appRoutes}</>));
  exposeToGlobalConsole({ desktopRouter });
  void desktopRouter.navigate('/vo');
  return desktopRouter;
};

const createWebAppRouter = () => {
  const webAppRouter = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route {...ro.WebShell}>
          <Route path="/" {...ro.BetaNotice} />
          <Route path="signup" {...ro.SignUp} />
          <Route path="signin/:token" {...ro.SignIn} />
          <Route path="signin" {...ro.SignIn} />
          <Route path="signout" {...ro.SignOut} />
          <Route {...ro.RequireAuth}>
            <Route path="open-desktop-app/:loginToken?" {...ro.OpenDesktopApp} />
            <Route path="link-with-slack" {...ro.LinkWithSlack}></Route>
          </Route>
          <Route path="login" element={<Navigate to="/signin" />} />
          <Route path="logout" element={<Navigate to="/signout" />} />
          <Route path="verify-email" {...ro.VerifyEmail} />
          <Route path="verify-email/:email" {...ro.VerifyEmail} />
          <Route path="link-account/:email/code/:code" {...ro.LinkAccount} />
          <Route
            path="google-login-redirect/:customToken/redirectToApp/:redirectToApp"
            {...ro.GoogleLoginRedirect}
          />
          <Route path="google-login-redirect/:error" {...ro.GoogleLoginRedirect} />
          <Route path="reset-password" {...ro.ResetPassword} />
          <Route path="reset-password/:encodedEmail" {...ro.ResetPassword} />
          <Route path="reset-password/:encodedEmail/code/:code" {...ro.ResetPassword} />
          <Route path="download" {...ro.DownloadApp} />
          <Route path="download/:platform" {...ro.DownloadAppPlatform} />
          <Route {...ro.RequireAuth}>
            <Route path="/onboarding/*" element={<Outlet />}>
              <Route path="create-new-org" {...ro.OnboardingCreateNewOrg} />
              <Route path="invite" {...ro.OnboardingInvite} />
              <Route path="linked-invite" {...ro.OnboardingLinkedInvite} />
            </Route>
            <Route path="/pricing" {...ro.Pricing} />
          </Route>
        </Route>

        {appRoutes}
      </>,
    ),
  );
  return webAppRouter;
};

export const desktopRouter = createDesktopRouter();
export const webRouter = createWebAppRouter();
export const router = isDesktopApp ? desktopRouter : webRouter;
setRouter(router);
