export const isProdEnv = 'prod' === process.env.POP_ENV;
export const isBetaEnv = 'beta' === process.env.POP_ENV;
export const isDevEnv = 'dev' === process.env.POP_ENV;
/**
 * @deprecated prefer productNameNoLocal or productNameMaybeLocal
 * In some places, we may want to distinguish between Pop-dev and Pop-local.
 */
export const productName = process.env.POP_ENV === 'prod' ? 'Pop' : 'Pop-' + process.env.POP_ENV;
export const productNameNoLocal = productName;
/**
 * @deprecated prefer protocolNameNoLocal or protocolNameMaybeLocal
 * In some places, we may want to distinguish between Pop-dev and Pop-local.
 */
export const protocolName = productNameNoLocal.toLowerCase();
export const protocolNameNoLocal = protocolName;
export const debOrRpmProductName = productNameNoLocal.toLowerCase();

export const sentryClientKey =
  'https://8b8bfbe176418beab2ea2e76d8dd89fe@o264355.ingest.us.sentry.io/4508065543290880';

export const sentryServerKey =
  'https://a1e18e6f3ac9665465a98ed0732b9e13@o264355.ingest.us.sentry.io/4508066072166400';
