import { i18n } from '@lingui/core';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { initCrashReporter } from 'utils/crash-reporter';
import { initializeFirebase } from 'utils/firebase-app';
import { registerServiceWorker, setupVitePreloadErrorHandler } from 'utils/service-worker';
import { storage } from 'utils/storage';

import { router } from './pages/vo/vo-react/router';

void initCrashReporter();
registerServiceWorker();
setupVitePreloadErrorHandler();
initializeFirebase({ shouldUseLocalhostAuth: !!storage.get('should-use-localhost-auth') });

async function getLocaleFile() {
  const detectedLocale = navigator.language.split('-')[0];

  if (detectedLocale === 'fr') {
    return { locale: 'fr', file: await import(`assets/locales/fr.po`) };
  }
  return { locale: 'en', file: await import(`assets/locales/en.po`) };
}

void getLocaleFile().then(({ locale, file: { messages } }) => {
  i18n.load(locale, messages);
  i18n.activate(locale);
});

createRoot(document.getElementById('root') as HTMLElement).render(<RouterProvider router={router} />);
