import { isElectron } from 'utils/react/user-agent';

export const setupVitePreloadErrorHandler = () => {
  if (isElectron) return;
  window.addEventListener('vite:preloadError', (event) => {
    // If we've failed to load a module on the website (which doesn't have a
    // service worker active), go ahead and reload the page to get the latest
    // assets (assuming this was a deployment cache invalidation).
    setTimeout(() => {
      window.location.reload();
    }, 0);
  });
};

export const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    void navigator.serviceWorker
      .register(import.meta.env.MODE === 'production' ? '/sw.js' : '/dev-sw.js?dev-sw', {
        type: import.meta.env.MODE === 'production' ? 'classic' : 'module',
      })
      .then((registration) => () => {
        // Immediately install waiting service workers on the browser. The
        // service worker does not cache the html page, so precaching is mostly
        // just to deploys messing with a users' current app

        if (isElectron) return;
        registration.addEventListener('waiting', () => {
          void installServiceWorkerUpdate();
        });
      });
  }
};

/**
 * Updating a service worker takes care; by default, the browser will leisurely
 * discover updates at its own cadence and queue the service worker for updating
 * once all the pages of a domain have offloaded (note that a reload of a page
 * doesn't count as offloading). The waiting phase can be prematurely ended by
 * asking the service worker to release its control directly (here via the
 * SKIP_WAITING message that workbox is looking for). The new version then is
 * able to take control and when the page is reloaded, the new service worker
 * with the new manifest is serving requests.
 */
export const installServiceWorkerUpdate = async () => {
  try {
    const registration = await navigator.serviceWorker.getRegistration();
    if (!registration) return;

    await registration.update();

    // If there's a service worker update that we just found out about and need
    // to install, wait for it and then skip the waiting period.
    if (registration.installing) {
      const newWorker = registration.installing;

      return new Promise<void>((resolve) => {
        newWorker.addEventListener('statechange', () => {
          if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
            newWorker.postMessage({ type: 'SKIP_WAITING' });
            resolve();
          }
        });
      });
    }

    // If we already passively found out about an update and it's waiting to be
    // installed (and we didn't find out about another update manually), go
    // ahead and skip waiting.
    if (registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      return;
    }
  } catch (error) {
    throw new Error(`Error checking for service worker update: ${error}`);
  }
};
