import * as base64ArrayBuffer from 'base64-arraybuffer';
import { TextDecoder, TextEncoder } from 'text-encoding';

const textEncoder = new TextEncoder();
const textDecoder = new TextDecoder();

export function encodeString(txt: any) {
  return base64ArrayBuffer.encode(textEncoder.encode(txt) as any);
}

export function decodeString(data: any) {
  return textDecoder.decode(base64ArrayBuffer.decode(data) as any);
}

export function encodeNumberArray(array: number[]) {
  return base64ArrayBuffer.encode(new Uint16Array(array) as any);
}
