import { sentryClientKey } from 'common/config/dev-config';

import { isBeta, isDesktopApp, isDev, isLocalDevApp, isLocalhost, isPackagedDevApp } from './client-utils';

const getEnvironment = () => {
  if (isLocalhost) return 'local';
  return process.env.POP_ENV;
};

export async function initCrashReporter() {
  const { init } = await getSentry();
  init({
    dsn: sentryClientKey,
    environment: getEnvironment(),
    release: `web-${__CLIENT_VERSION__}`,
    enabled: !isLocalhost,
    debug: getEnvironment() === 'dev' || getEnvironment() === 'local',
  });
}

export async function setCrashReporterUserId(userId: string | null) {
  const { setUser } = await getSentry();
  setUser(userId ? { id: userId } : null);
}

const getSentry = () => (isDesktopApp ? import('@sentry/electron/renderer') : import('@sentry/browser'));
